/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// ----------------------------------------------------------------------

import Router from 'src/routes/sections';

import ThemeProvider from 'src/theme';

import esLocale from 'date-fns/locale/es';
import { LocalizationProvider } from 'src/locales';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ProgressBar from 'src/components/progress-bar';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';

import { ECTInputsProvider } from 'src/components/ICDInputs/context/ECTInputsContext';

import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';

import { AuthProvider } from 'src/auth/context/jwt';

import { DiagnosticFormProvider } from 'src/components/DiagnosticContext/diagnostic-provider';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider locale={esLocale}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'green' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <DiagnosticFormProvider>
            <ECTInputsProvider>
              <ThemeProvider>
                <MotionLazy>
                  <SnackbarProvider>
                    <SettingsDrawer />
                    <ProgressBar />
                    <Router />
                  </SnackbarProvider>
                </MotionLazy>
              </ThemeProvider>
            </ECTInputsProvider>
          </DiagnosticFormProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
