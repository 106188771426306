import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

// ----------------------------------------------------------------------

const RHFSwitch = forwardRef(({ name, helperText, ...other }, ref) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel
            control={<Switch {...field} ref={ref} checked={field.value} />}
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
});

RHFSwitch.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string,
};

RHFSwitch.displayName = 'RHFSwitch';

export default RHFSwitch;
