import PropTypes from 'prop-types';
import { useMemo, useState, useContext, createContext } from 'react';

const ECTInputsContext = createContext();

const INITIAL_INPUTS = {
  currentNumber: 1,
  takenNumbers: new Set(),
  initialized: new Set(),
};

export function ECTInputsProvider({ children }) {
  const [inputs, setInputs] = useState(INITIAL_INPUTS);

  const getNextAvailableNumber = () => {
    let number = inputs.currentNumber;
    while (inputs.takenNumbers.has(number)) {
      number += 1;
    }
    return number;
  };

  const value = useMemo(
    () => ({
      inputs,
      takeNumber: () => {
        setInputs((prev) => {
          const nextNumber = getNextAvailableNumber(prev);
          return {
            currentNumber: nextNumber + 1,
            takenNumbers: new Set([...prev.takenNumbers, nextNumber]),
          };
        });
        return getNextAvailableNumber(inputs);
      },
      addNumber: (number) => {
        setInputs((prev) => ({
          ...prev,
          takenNumbers: new Set([...prev.takenNumbers, number]),
        }));
      },
      numberExists: (number) => inputs.takenNumbers.has(number),
      getAllNumbers: () => Array.from(inputs.takenNumbers),
      releaseNumber: (number) => {
        setInputs((prev) => ({
          ...prev,
          takenNumbers: new Set([...prev.takenNumbers].filter((n) => n !== number)),
        }));
      },
      resetNumbers: () => {
        setInputs(INITIAL_INPUTS);
      },
      isInitialized: (number) => inputs.initialized.has(number),
      setInitialized: (number) => {
        setInputs((prev) => ({
          ...prev,
          initialized: new Set([...prev.initialized, number]),
        }));
      },
      clearInitialized: (number) => {
        setInputs((prev) => ({
          ...prev,
          initialized: new Set([...prev.initialized].filter((n) => n !== number)),
        }));
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputs]
  );

  return <ECTInputsContext.Provider value={value}>{children}</ECTInputsContext.Provider>;
}

ECTInputsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useECTInputs() {
  return useContext(ECTInputsContext);
}
