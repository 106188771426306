import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';

import DiagnosticFormContext from './diagnostic-context';

const LOCAL_STORAGE_KEY = 'unsavedDiagnosticForms';

export const DiagnosticFormProvider = ({ children }) => {
  const [diagnosticForms, setDiagnosticForms] = useState([]);

  useEffect(() => {
    const stored = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (stored) {
      try {
        setDiagnosticForms(JSON.parse(stored));
      } catch (e) {
        console.error('Error parsing localStorage data:', e);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(diagnosticForms));
  }, [diagnosticForms]);

  const updateForm = (admissionId, formData) => {
    setDiagnosticForms((prev) => {
      const existingIndex = prev.findIndex((f) => f.AdmissionID === admissionId);
      if (existingIndex !== -1) {
        const updated = [...prev];
        updated[existingIndex] = { ...updated[existingIndex], ...formData };
        return updated;
      }
      return [...prev, { AdmissionID: admissionId, ...formData }];
    });
  };

  const getForm = (admissionId) => diagnosticForms.find((f) => f.AdmissionID === admissionId);

  const removeForm = (admissionId) => {
    setDiagnosticForms((prev) => prev.filter((f) => f.AdmissionID !== admissionId));
  };

  const contextValue = useMemo(
    () => ({
      diagnosticForms,
      updateForm,
      getForm,
      removeForm,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [diagnosticForms]
  );

  return (
    <DiagnosticFormContext.Provider value={contextValue}>{children}</DiagnosticFormContext.Provider>
  );
};

DiagnosticFormProvider.propTypes = {
  children: PropTypes.node,
};
