import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = import.meta.env.VITE_HOST_API;
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;
export const AMBIR_API = import.meta.env.VITE_AMBIR_SCANNER_PORT;
export const MEDICORE_OCR_API = import.meta.env.VITE_MEDICORE_OCR_API;
export const PRODUCTION = import.meta.env.VITE_PRODUCTION;
export const TEST_USER_USERNAME = import.meta.env.VITE_TEST_USER_USERNAME;
export const TEST_USER_PASSWORD = import.meta.env.VITE_TEST_USER_PASSWORD;

export const FIREBASE_API = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: import.meta.env.VITE_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = import.meta.env.VITE_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.medicore.root; // as '/dashboard'
export const PATH_AFTER_PATIENT_LOGIN = '/patient/patient-portal/digital-report'; // as '/patient/patient-portal/digital-report'
export const ICD_API = import.meta.env.VITE_ICD_API;
export const ICD_VERSION = import.meta.env.VITE_ICD_VERSION;
export const ICD_API_LANGUAGE = import.meta.env.VITE_ICD_API_LANGUAGE;
