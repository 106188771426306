/* eslint-disable perfectionist/sort-imports */
import 'src/utils/highlight';
import { forwardRef } from 'react';

import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

import { alpha } from '@mui/material/styles';

import { StyledEditor } from './styles';
import Toolbar, { formats } from './toolbar';

// ----------------------------------------------------------------------

const Editor = forwardRef(({ id, error, simple, helperText, sx, ...other }, ref) => {
  const defaultId = id || 'minimal-quill';
  const defaultSimple = simple || false;

  const modules = {
    toolbar: {
      container: `#${defaultId}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <Toolbar id={defaultId} simple={defaultSimple} />

        <ReactQuill
          modules={modules}
          formats={formats}
          placeholder="Escribir detalles..."
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
});

Editor.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.object,
  id: PropTypes.string,
  simple: PropTypes.bool,
  sx: PropTypes.object,
};

Editor.displayName = 'Editor';

export default Editor;
